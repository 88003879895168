import { LOGIN_ENDPOINT, REFRESH_TOKEN_ENDPOINT } from '../../../config.json';

const TOKEN_NAME = 'authToken';
const REFRESH_TOKEN_NAME = 'refreshToken';

export const useAuthToken = () => {
  const getAuthToken = (): string | null => {
    const storedToken = localStorage.getItem(TOKEN_NAME);
    if (storedToken && storedToken !== 'undefined') {
      return storedToken;
    } else {
      return null;
    }
  };

  const getRefreshToken = (): string | null => {
    const storedToken = localStorage.getItem(REFRESH_TOKEN_NAME);
    if (storedToken && storedToken !== 'undefined') {
      return storedToken;
    } else {
      return null;
    }
  };

  const setAuthToken = (authToken: string) => {
    if (authToken) {
      return localStorage.setItem(TOKEN_NAME, authToken);
    }

    return;
  };

  const setRefreshToken = (token: string) => {
    if (token) {
      return localStorage.setItem(REFRESH_TOKEN_NAME, token);
    }

    return;
  };

  const removeAuthToken = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
  };

  function login(
    username: string,
    password: string,
    successCallback: { (data: any): void; (arg0: any): void },
    errorCallback: { (): void; (): void }
  ) {
    fetch(LOGIN_ENDPOINT, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          errorCallback();
          return;
        }
      })
      .then((data) => {
        successCallback(data);
      });
  }

  function refreshToken() {
    return fetch(REFRESH_TOKEN_ENDPOINT, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: getRefreshToken() }),
    }).then((response) => {
      return response.json();
    });
  }

  return {
    getAuthToken,
    setAuthToken,
    removeAuthToken,
    setRefreshToken,
    getRefreshToken,
    login,
    refreshToken,
  };
};
