import {
  ApolloClient,
  InMemoryCache,
  from,
  fromPromise,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import DebounceLink from 'apollo-link-debounce';
import { useAuthToken } from '../hooks/useAuthToken';
import {
  httpDashboardLink,
  httpPrivateLink,
  httpPublicLink,
} from './httpLinks';
import typePolicies from './typePolicies';

const authLink = setContext((_, { headers }) => {
  const { getAuthToken } = useAuthToken();
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      AccessToken: token ? `Bearer ${token}` : '',
    },
  };
});

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(({ networkError, operation, forward }: any) => {
  if (networkError) {
    const { refreshToken, setAuthToken, setRefreshToken } = useAuthToken();
    if (networkError.statusCode === 401) {
      let forward$;

      if (!isRefreshing) {
        isRefreshing = true;
        forward$ = fromPromise(
          refreshToken()
            .then(({ token, refresh_token }) => {
              // Store the new tokens for your auth link
              setAuthToken(token);
              setRefreshToken(refresh_token);
              resolvePendingRequests();

              if (!token) {
                throw new Error('Token is empty');
              }

              return token;
            })
            .catch((error) => {
              pendingRequests = [];
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('authToken');
              return;
            })
            .finally(() => {
              isRefreshing = false;
            })
        ).filter((value) => Boolean(value));
      } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
          new Promise((resolve) => {
            pendingRequests.push(() => resolve());
          })
        );
      }

      return forward$.flatMap(() => forward(operation));
    }
  }
  return;
});

const debounceLink = new DebounceLink(100);

function buildDashboardClient() {
  return new ApolloClient({
    link: from([
      errorLink,
      authLink,
      (debounceLink as unknown) as ApolloLink,
      httpDashboardLink,
    ]),
    cache: new InMemoryCache({ typePolicies }),
  });
}

function buildPrivateClient() {
  return new ApolloClient({
    link: from([
      errorLink,
      authLink,
      (debounceLink as unknown) as ApolloLink,
      httpPrivateLink,
    ]),
    cache: new InMemoryCache({ typePolicies }),
  });
}

function buildPublicClient() {
  return new ApolloClient({
    link: from([
      errorLink,
      (debounceLink as unknown) as ApolloLink,
      httpPublicLink,
    ]),
    cache: new InMemoryCache({ typePolicies }),
  });
}

function build(type: string) {
  switch (type) {
    case 'public':
      return buildPublicClient();
    case 'dashboard':
      return buildDashboardClient();
    default:
      return buildPrivateClient();
  }
}

export default build('private');

export const buildClient = build;
