import { text } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

type TitleProps = {
  title:
    | string
    | { text: { refGT: string; refClient: string }; souffrance: boolean };
};

const Title = ({ title }: TitleProps) => {
  if (typeof title === 'object') {
    return (
      <>
        <h1 className="title">
          <div className="title-row">
            <p className="title-gt-ref">
              Référence GT : {title.text.refGT}
              <span
                className={`souffrance-indicator ${
                  title.souffrance ? 'with-souffrance' : ''
                }`}
              />
            </p>
          </div>
          <div className="title-row">
            <p className="title-client-ref">
              Référence client : {title.text.refClient}
            </p>
          </div>
        </h1>
        {window.location.hostname === 'gtlink.rct.gt-solutions.fr' && (
          <p
            style={{
              color: 'red',
              marginLeft: '20px',
              fontSize: '1.5rem',
            }}
          >
            RECETTE
          </p>
        )}
      </>
    );
  } else {
    return (
      <>
        <h1 className="title">{title}</h1>
        {window.location.hostname === 'gtlink.rct.gt-solutions.fr' && (
          <p
            style={{
              color: 'red',
              marginLeft: '20px',
              fontSize: '1.5rem',
            }}
          >
            RECETTE
          </p>
        )}
      </>
    );
  }
};

export default Title;
