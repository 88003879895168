import React from 'react';
import NavbarIcon, { NavbarIconType } from './NavbarIcon';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faDashboardIcon } from '../../../../theme/img/icons/customicons/dashboard';
import { Link } from 'react-router-dom';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { useQuery, useApolloClient } from '@apollo/client';
import { FETCH_ME } from '../../../graphql/users/queries';

const Navbar = React.memo(() => {
  const { removeAuthToken } = useAuthToken();
  const client = useApolloClient();

  const handleClick = (name: string) => {
    if (name === 'signout') {
      removeAuthToken();
      client.cache.reset();
    }
  };

  const { loading, error, data: myData } = useQuery(FETCH_ME);

  if (!myData) {
    return <></>;
  }

  const navbarLists: NavbarIconType[] = [
    {
      name: 'dashboard_summary',
      label: 'Tableau de bord synthèse',
      iconDefinition: faDashboardIcon,
      title: 'Accédez au tableau de bord synthèse',
      link: '/dashboard-synthese',
      authorized: myData.Me.dashboardSummaryAuthorized,
    },
    {
      name: 'dashboard',
      label: 'Tableau de bord',
      iconDefinition: faDashboardIcon,
      title: 'Accédez au tableau de bord',
      link: '/dashboard',
      authorized: myData.Me.dashboardAuthorized,
    },
    {
      name: 'search',
      label: 'Recherche',
      iconDefinition: faSearch,
      title: 'Accédez à la page de recherche',
      link: '/recherche',
      authorized: true,
    },
    {
      name: 'settings',
      label: 'Paramètres',
      iconDefinition: faCog,
      title: 'Accéder aux paramètres',
      link: '/parametres',
      authorized: [
        'ROLE_SUPER_ADMIN',
        'ROLE_PRIMARY_CUSTOMER_ADMIN',
        'ROLE_SECONDARY_CUSTOMER_ADMIN',
        'ROLE_PRIMARY_SUPPLIER_ADMIN',
      ].includes(myData.Me.roles),
    },
    {
      name: 'signout',
      label: 'Déconnexion',
      iconDefinition: faSignOut,
      title: 'Se déconnecter',
      link: '/connexion',
      authorized: true,
    },
  ];

  return (
    <aside id="sidebar">
      <ol>
        {navbarLists.map((item: NavbarIconType) => {
          if (item.authorized) {
            return (
              <li key={item.name} className={item.name}>
                <Link to={item.link} onClick={() => handleClick(item.name)}>
                  <NavbarIcon {...item} />
                </Link>
              </li>
            );
          }
          return <></>;
        })}
      </ol>
    </aside>
  );
});

export default Navbar;
