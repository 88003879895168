export const routes = {
  root: '/',
  dashboard: '/dashboard',
  dashboardSummary: '/dashboard-synthese',
  createUser: '/nouvel-utilisateur',
  profile: '/profil',
  search: '/recherche',
  position: '/position',
  pod: '/pod',
  accessPod: '/access-pod',
  accessPos: '/pos/:token',
  users: '/utilisateurs',
  exports: '/exports',
  forgottenPassword: '/mot-de-passe-oublie',
  forgottenPasswordConfirm: '/mot-de-passe-oublie/confirmation',
  login: '/connexion',
  userToken: '/jeton-utilisateur',
  parameters: '/parametres',
  codeManagement: '/gestion-des-codes',
  legalsCookie: '/politique-d-utilisation-des-cookies',
  legalsRGPD: '/protection-des-donnees',
};

export const routesWithLayout = [
  routes.root,
  routes.dashboard,
  routes.createUser,
  routes.profile,
  routes.users,
  routes.search,
  routes.position,
  routes.exports,
  routes.parameters,
  routes.codeManagement,
];

export const routesWithoutLogin = [
  routes.login,
  routes.forgottenPassword,
  routes.forgottenPasswordConfirm,
  routes.pod,
  routes.accessPod,
  routes.accessPos,
  routes.legalsCookie,
  routes.legalsRGPD,
];

export const routesWithNewClaimButton = [
  routes.dashboard,
  routes.search,
];
