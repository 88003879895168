import { gql } from '@apollo/client';

const FETCH_ME = gql`
  query Me {
    Me {
      lastName
      firstName
      id
      email
      roles
      authorizations
      searchAuthorized
      dashboardAuthorized
      dashboardSummaryAuthorized
      summaryAuthorized
      supplierCodes
      widgets
      hasCreated {
        id
      }
      company {
        id
        name
        address
        city
        country
        zipCode
        phoneNumber
        faxNumber
        website
      }
    }
  }
`;

const FETCH_MY_ROLE = gql`
  query Me {
    Me {
      roles
    }
  }
`;

const COMPANY_FRAGMENT = gql`
  fragment Company on User {
    company {
      id
      name
      address
      zipCode
      city
      country
      phoneNumber
      faxNumber
      website
      createdAt
    }
  }
`;

const FETCH_USER = gql`
  query ViewUser($uid: Int!) {
    ViewUser(uid: $uid) {
      lastName
      firstName
      id
      email
      widgets
      roles
      authorizations
      searchAuthorized
      dashboardAuthorized
      summaryAuthorized
      dashboardSummaryAuthorized
      clientCodes
      supplierCodes
      ...Company
    }

    AvailableWidgetsForUser(uid: $uid)
  }
  ${COMPANY_FRAGMENT}
`;

const FETCH_AVAILABLE_WIDGET = gql`
  query AvailableWidgetsForUser{
    AvailableWidgetsForUser
  }
`;

const FETCH_USERS = gql`
  query Users(
    $page: Int
    $perPage: Int
    $filters: UserSearchFilter
    $companyFilters: CompanySearchFilter
    $order: UserSearchSort
  ) {
    Users(
      page: $page
      perPage: $perPage
      order: $order
      filters: $filters
      companyFilters: $companyFilters
    ) {
      maxPerPage
      nbPages
      nbResults
      results {
        id
        firstName
        lastName
        email
        lastLoginAt
        roles
        company {
          name
        }
      }
    }
  }
`;

const FETCH_CLIENT_TOKENS = gql`
  query ClientTokens($page: Int, $perPage: Int) {
    ClientTokens(page: $page, perPage: $perPage) {
      maxPerPage
      nbPages
      nbResults
      results {
        id
        clientCodes
        token
        createdAt
        allClients
      }
    }
  }
`;

const FETCH_EXPORTS = gql`
  query ExportState {
    ExportState {
      id
      percentage
      totalOrders
      startAt
      fileId
    }
  }
`;

const FETCH_EXPORT_PERCENTAGE = gql`
  query ExportPercentage($userExportOrderId: Int!) {
    ExportPercentage(userExportOrderId: $userExportOrderId)
  }
`;

const FETCH_USED_ROLES = gql`
  query FetchUsedRoles {
    FetchUsedRoles
  }
`;

export {
  FETCH_ME,
  FETCH_MY_ROLE,
  FETCH_USERS,
  FETCH_USER,
  FETCH_CLIENT_TOKENS,
  FETCH_EXPORTS,
  FETCH_EXPORT_PERCENTAGE,
  FETCH_USED_ROLES,
  FETCH_AVAILABLE_WIDGET
};
