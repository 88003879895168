import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../pages/routes';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { useApolloClient } from '@apollo/client';

type UserDropdownProps = {
  id: number;
  isCustomer: boolean;
};

const UserDropdown = ({ id, isCustomer }: UserDropdownProps) => {
  const { removeAuthToken } = useAuthToken();
  const client = useApolloClient();

  return (
    <div className="dropdown">
      {!isCustomer && (
        <div className="dropdown-item">
          <Link to={`${routes.profile}/${id}`}>Modifier le profil</Link>
        </div>
      )}
      <div className="dropdown-item">
        <Link
          to={routes.login}
          onClick={function () {
            removeAuthToken();
            client.cache.reset();
          }}
        >
          Se déconnecter
        </Link>
      </div>
    </div>
  );
};

export default UserDropdown;
