export const countries = [
  { value: 'France', label: 'France' },
  { value: 'Belgique', label: 'Belgique' },
  { value: 'Suisse', label: 'Suisse' },
];

export const allWidgets = [
  { label: 'Statuts des positions', value: '5' },
  { label: 'Qualité de livraison', value: '6' },
  { label: 'Taux de POD', value: '7' },
  { label: 'Statut des enlèvements', value: '22' },
  { label: 'Délais de livraison réel', value: '8' },
  { label: 'Délais de livraison brut', value: '20' },
  { label: 'Nombre de positions', value: '9' },
  { label: 'Tonnage transporté', value: '10' },
  { label: 'UM Transportées', value: '11' },
  { label: 'Poids moyen par expédition', value: '12' },
  { label: 'Performance de livraison', value: '13' },
  { label: 'Taux de restitutions POD', value: '14' },
  { label: 'Délais de livraison (carte)', value: '15' },
  {
    label: 'Répartition des livraisons par nombre de positions (carte)',
    value: '16',
  },
  { label: 'Zone de livraison par poids (carte)', value: '17' },
  { label: 'Répartition par tranche de poids', value: '18' },
  { label: 'Respect des délais (carte)', value: '19' },
  { label: 'Respect des délais brut (carte)', value: '21' },
  { label: 'Délais d\'enlèvement (carte)', value: '23' },
  { label: 'Délais de retour des enlèvements (carte)', value: '24' },
];

export const userTypes = [
  { value: 'ROLE_SUPER_ADMIN', label: 'Super Admin' },
  { value: 'ROLE_PRIMARY_CUSTOMER_ADMIN', label: 'Client Principal' },
  { value: 'ROLE_SECONDARY_CUSTOMER_ADMIN', label: 'Client Secondaire' },
  // { value: 'ROLE_CUSTOMER', label: 'Client' },
  // { value: 'ROLE_PRIMARY_SUPPLIER_ADMIN', label: 'Transporteur Principal' },
  { value: 'ROLE_SUPPLIER', label: 'Transporteur' },
  // { value: 'ROLE_PRIMARY_GT_ADMIN', label: 'Admin GT Principal' },
  // { value: 'ROLE_SECONDARY_GT_ADMIN', label: 'Admin GT Secondaire' },
  { value: 'ROLE_GT', label: 'Utilisateur GT' },
];

export function userIsGT(userRole: string | null = null) {
  return (
    userRole === 'ROLE_PRIMARY_GT_ADMIN' ||
    userRole === 'ROLE_SECONDARY_GT_ADMIN' ||
    userRole === 'ROLE_GT'
  );
}

export function userIsCustomer(userRole: string | null = null) {
  return (
    userRole === 'ROLE_CUSTOMER' ||
    userRole === 'ROLE_SECONDARY_CUSTOMER_ADMIN' ||
    userRole === 'ROLE_PRIMARY_CUSTOMER_ADMIN'
  );
}

export function userIsSuperAdmin(userRole: string | null = null) {
  return userRole === 'ROLE_SUPER_ADMIN';
}

export function userIsSupplier(userRole: string | null = null) {
  return (
    userRole === 'ROLE_PRIMARY_SUPPLIER_ADMIN' || userRole === 'ROLE_SUPPLIER'
  );
}

export function canHaveSummary(userRole: string | null = null) {
  return (
    userRole === 'ROLE_SUPER_ADMIN' ||
    userRole === 'ROLE_SUPPLIER' ||
    userRole === 'ROLE_PRIMARY_CUSTOMER_ADMIN'
  );
}

export function canAccessToDashboardSummary(userRole: string | null = null) {
  return userIsSuperAdmin(userRole) || userIsGT(userRole);
}
