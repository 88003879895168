import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons/index';

export type NavbarIconType = {
  name: string;
  label: string;
  iconDefinition: IconDefinition;
  title: string;
  link: string;
  authorized: boolean;
};

const NavbarIcon = (props: NavbarIconType) => {
  return (
    <>
      <FontAwesomeIcon
        icon={props.iconDefinition}
        size="2x"
        color="#FFF"
        title={props.title}
      />
      <p className="navbar-label">{props.label}</p>
    </>
  );
};
export default NavbarIcon;
