import React, { FunctionComponent, useState } from 'react';
import { TitleContext } from './TitleContext';
import Header from './header/Header';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';

type LayoutProps = {
  withHeader?: boolean;
  withFooter?: boolean;
  withSidebar?: boolean;
  extraClass?: string;
};

const Layout: FunctionComponent<LayoutProps> = ({
  withFooter = true,
  withHeader = true,
  withSidebar = true,
  children,
  extraClass = '',
}) => {
  const [title, setTitle] = useState('');
  return (
    <TitleContext.Provider
      value={{
        title: '',
        setTitle,
      }}
    >
      {withHeader && <Header title={title} />}
      <div className={`component-container`}>
        <main className={`layout ${extraClass}`}>{children}</main>
        {withSidebar && <Navbar />}
      </div>
      {withFooter && <Footer extraClass={extraClass} />}
    </TitleContext.Provider>
  );
};

export default Layout;
