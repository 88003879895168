import { createHttpLink } from '@apollo/client';

import {
  GRAPHQL_ENDPOINT,
  GRAPHQL_PUBLIC_ENDPOINT,
  DASHBOARD_ENDPOINT,
} from '../../../config.json';

export const httpPrivateLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
});

export const httpPublicLink = createHttpLink({
  uri: GRAPHQL_PUBLIC_ENDPOINT,
});

export const httpDashboardLink = createHttpLink({
  uri: DASHBOARD_ENDPOINT,
});
