import React, { Suspense, useEffect } from 'react';
import '../theme/scss/App.scss';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Layout from './components/layout/Layout';
config.autoAddCss = false;

// PAGES
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const DashboardSummary = React.lazy(() => import('./pages/DashboardSummary'));
const Login = React.lazy(() => import('./pages/Login'));
const CreateUser = React.lazy(() => import('./pages/CreateUser'));
const Users = React.lazy(() => import('./pages/Users'));
const Exports = React.lazy(() => import('./pages/Exports'));
const Search = React.lazy(() => import('./pages/Search'));
const Order = React.lazy(() => import('./pages/Order'));
const AccessPod = React.lazy(() => import('./pages/AccessPod'));
const ForgottenPassword = React.lazy(() => import('./pages/ForgottenPassword'));
const UserToken = React.lazy(() => import('./pages/UserToken'));
const ForgottenPasswordConfirm = React.lazy(() =>
  import('./pages/ForgottenPasswordConfirm')
);
const Parameters = React.lazy(() => import('./pages/Parameters'));
const CodeManagement = React.lazy(() => import('./pages/CodeManagement'));
// legals
const LegalsCookie = React.lazy(() => import('./pages/LegalsCookie'));
const LegalsRGPD = React.lazy(() => import('./pages/LegalsRGPD'));

import { routes, routesWithoutLogin, routesWithLayout } from './pages/routes';
import { useAuthToken } from './hooks/useAuthToken';
import { useQuery } from '@apollo/client';
import { FETCH_ME } from './graphql/users/queries';
import Unauthorized from './pages/Unauthorized';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const { getAuthToken, getRefreshToken } = useAuthToken();
  const { data: myData, loading } = useQuery(FETCH_ME);
  const posPage = location.pathname.split('/');

  useEffect(() => {
    const jwt = getAuthToken();
    const refreshToken = getRefreshToken();

    if (
      (!jwt || !refreshToken) &&
      !routesWithoutLogin.includes(location.pathname)
    ) {
      let redirect = false;
      routesWithoutLogin.forEach(function (route: string) {
        // if the route contains a placeholder => we use a regex pattern to match
        if (route.indexOf(':') > -1) {
          const parts = route.split('/').map(function (part: string) {
            // replace the current part of the route by a regex pattern
            if (part.charAt(0) === ':') {
              return '(.+)';
            }

            // no placeholder in this part of the route, we should match exactly the part (so no regex)
            return part;
          });

          redirect =
            redirect ||
            location.pathname.match(new RegExp(parts.join('/'))) === null;
        } else {
          redirect = redirect || location.pathname === route;
        }
      });
      if (redirect) {
        history.push(routes.login);
      }
    }
  }, [location]);

  const userHasAccess = (params: { id: number }) => {
    const userRole = myData?.Me.roles;
    if (userRole === 'ROLE_PRIMARY_CUSTOMER_ADMIN') {
      let userIsCreatedByMe = false;
      myData?.Me.hasCreated.forEach((user: { id: number }) => {
        if (user.id === +params.id) {
          userIsCreatedByMe = true;
        }
      });
      return userIsCreatedByMe;
    }

    const customerRoles = ['ROLE_SECONDARY_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'];
    return !customerRoles.includes(userRole);
  };

  return (
    <Switch>
      <Route path={routesWithoutLogin}>
        <Switch>
          <Layout withHeader={false} withFooter={false} withSidebar={false}>
            <Suspense fallback={<div />}>
              <Route
                exact
                path={routes.forgottenPassword}
                component={ForgottenPassword}
              />
              <Route
                exact
                path={routes.forgottenPasswordConfirm}
                component={ForgottenPasswordConfirm}
              />
              <Route exact path={routes.login} component={Login} />
              <Route exact path={routes.pod} component={Order} />
              <Route exact path={routes.accessPod} component={AccessPod} />
              <Route exact path={routes.accessPos} component={AccessPod} />
              <Route
                exact
                path={routes.legalsCookie}
                component={LegalsCookie}
              />
              <Route exact path={routes.legalsRGPD} component={LegalsRGPD} />
            </Suspense>
          </Layout>
        </Switch>
      </Route>
      <Route path={routesWithLayout}>
        <Switch>
          <Layout extraClass="with-sidebar">
            <Suspense fallback={<div />}>
              <Route exact path={routes.createUser} component={CreateUser} />
              <Route
                exact
                path={`${routes.profile}/:id`}
                render={(props) => {
                  return userHasAccess(props.match.params) ? (
                    <CreateUser />
                  ) : (
                    <Unauthorized />
                  );
                }}
              />
              <Route exact path={routes.users} component={Users} />
              <Route exact path={routes.search} component={Search} />
              <Route exact path={routes.exports} component={Exports} />
              <Route exact path={routes.parameters} component={Parameters} />
              <Route
                exact
                path={routes.codeManagement}
                render={() => {
                  return 'ROLE_SUPER_ADMIN' === myData?.Me.roles ? (
                    <CodeManagement />
                  ) : (
                    <Unauthorized />
                  );
                }}
              />
              <Route
                exact
                path={`${routes.position}/:clientCode/:orderId`}
                component={Order}
              />
              <Route exact path={routes.dashboard} component={Dashboard} />
              <Route
                exact
                path={routes.dashboardSummary}
                component={DashboardSummary}
              />
              <Route
                exact
                path={routes.root}
                render={() => <Redirect to={routes.search} />}
              />
              <Route exact path={routes.userToken} component={UserToken} />
            </Suspense>
          </Layout>
        </Switch>
      </Route>
    </Switch>
  );
};

export default App;
