import React from 'react';
import logo from '../../../../theme/img/gt-logo-transparent.png';
import { Link } from 'react-router-dom';
import { routes } from '../../../pages/routes';

const Logo = () => (
  <div className="logo-container">
    <Link to={routes.search}>
      <img src={logo} alt="GT SOLUTIONS" className="logo" />
    </Link>
  </div>
);

export default Logo;
