import React, { useState, useRef, useEffect } from 'react';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserDropdown from './UserDropdown';

type UserProps = {
  firstName: string;
  lastName: string;
  id: number;
  isCustomer: boolean;
};

const User = ({ firstName, lastName, id, isCustomer }: UserProps) => {
  const [dropdownOpen, set] = useState(false);
  const node = useRef<HTMLInputElement>(null);

  function handleClick() {
    set((prev) => !prev);
  }

  const handleClickOutside = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    set(false);
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <>
      <div className="user-container" ref={node}>
        <a role="presentation" onClick={handleClick}>
          <div className="user-picto-container">
            <FontAwesomeIcon className="user-picto" icon={faUser} />
          </div>
          <p className="user-name">
            {firstName} {lastName}
          </p>
        </a>
        {dropdownOpen && <UserDropdown id={id} isCustomer={isCustomer} />}
      </div>
    </>
  );
};

export default User;
