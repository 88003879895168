import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Unauthorized = () => {
  React.useEffect(() => {
    document.title = 'GT Link';
  }, []);

  return (
    <div className="unauthorized">
      <FontAwesomeIcon icon={faTimesCircle} />
      <p>Vous n'avez pas accès à cette page</p>
    </div>
  );
};

export default Unauthorized;
