import dayjs from 'dayjs';
import { orddetType, ordtaxeType } from '../types/businessDataType';

function formatFiltersForMultiSelect(filters: any[]) {
  if (!filters) {
    return null;
  }
  if (filters[0].label && filters[0].value) {
    return filters;
  }
  return filters.map((f) => ({ label: f, value: f }));
}

export default {
  User: {
    fields: {
      roles: {
        read(roles: string[]) {
          return roles[0];
        },
      },
      clientCodes: {
        read(codes: string[]) {
          if (!codes) {
            return null;
          }

          return codes?.map((c) => ({ label: c, value: c }));
        },
      },
      supplierCodes: {
        read(codes: string[]) {
          if (!codes) {
            return null;
          }

          return (codes || []).map((c) => ({ label: c, value: c }));
        },
      },
      lastLoginAt: {
        read(date: string) {
          return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '';
        },
      },
    },
  },
  Order: {
    fields: {
      ordtaxe: {
        read(ordtaxe: ordtaxeType[]) {
          return ordtaxe.map((o) => ({
            ...o,
            ottpoids: Math.round(+o.ottpoids * 1000),
          }));
        },
      },
      orddet: {
        read(orddet: orddetType[]) {
          return orddet.map((ord) => ({
            ...ord,
            otddate: ord.otddate
              ? dayjs(ord.otddate).format('DD/MM/YYYY')
              : 'N/D',
          }));
        },
      },
    },
  },
  DashboardFilter: {
    fields: {
      clientCodes: {
        read(clientCodes: string[]) {
          return formatFiltersForMultiSelect(clientCodes);
        },
      },
      loadZones: {
        read(loadZones: string[]) {
          return formatFiltersForMultiSelect(loadZones);
        },
      },
      deliveryZones: {
        read(deliveryZones: string[]) {
          return formatFiltersForMultiSelect(deliveryZones);
        },
      },
      recipientName: {
        read(name: string[]) {
          return formatFiltersForMultiSelect(name);
        },
      },
      partnerCodes: {
        read(name: string[]) {
          return formatFiltersForMultiSelect(name);
        },
      },
    },
  },
  LastOrders: {
    fields: {
      allOrders: {
        read(value: number) {
          if (value) {
            return formatBigNumber(value);
          }
          return 0;
        },
      },
    },
  },
  OrderStates: {
    fields: {
      allOrders: {
        read(value: number) {
          if (value) {
            return formatBigNumber(value);
          }
          return 0;
        },
      },
    },
  },
  DeliveryQuality: {
    fields: {
      allOrders: {
        read(value: number) {
          if (value) {
            return formatBigNumber(value);
          }
          return 0;
        },
      },
    },
  },
  DeliveryOrders: {
    fields: {
      allOrders: {
        read(value: number) {
          if (value) {
            return formatBigNumber(value);
          }
          return 0;
        },
      },
    },
  },
  DeliveryDelay: {
    fields: {
      allOrders: {
        read(value: number) {
          if (value) {
            return formatBigNumber(value);
          }
          return 0;
        },
      },
    },
  },
  RateTypePod: {
    fields: {
      allOrders: {
        read(value: number) {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
      },
    },
  },
};

function formatBigNumber(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
