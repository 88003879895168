import { useEffect } from 'react';

type inlineScriptsParams = {
    script: string,
};

export default function useInlineScripts({ script }: inlineScriptsParams) {
    useEffect(() => {
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = script;

        document.body.appendChild(inlineScript);

        return () => {
            inlineScript.remove();
        };
    }, [script]);
}
