import React, {
  useEffect,
  useState,
  ChangeEvent,
  FormEvent,
  MouseEvent,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useHistory } from 'react-router-dom';
import useSearchParams from '../../../hooks/useSearchParams';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const Searchbar = () => {
  const searchParams = useSearchParams();
  const [query, setQuery] = useState<any>('');
  const history = useHistory();

  useEffect(() => {
    setQuery(getValueFromUrl() || '');
  }, [searchParams.get('filters')]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const getValueFromUrl = () => {
    const filtersParams = searchParams.get('filters');
    let parsed;
    if (filtersParams) {
      parsed = JSON.parse(filtersParams);
    }
    return parsed?.term;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let qs = '';
    qs = JSON.stringify({ term: query });
    history.push(`/recherche?filters=${qs}`);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    setQuery('');
    history.push(`/recherche?filters={}`);
  };

  return (
    <div className="searchbar-container">
      <div className="icon">
        <FontAwesomeIcon icon={faSearch} size="lg" />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          className="searchbar"
          type="text"
          placeholder="Rechercher un référence, un code postal, des mots de l'adresse"
          value={query}
          onChange={onInputChange}
        />
        {!!query.length && (
          <button
            type="button"
            className="delete-search-term"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <button type="submit" className="btn submit-search">
          OK
        </button>
      </form>
    </div>
  );
};

export default Searchbar;
