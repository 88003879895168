import React, { useEffect, useState } from 'react';
import Searchbar from './Searchbar';
import Logo from './Logo';
import Title from './Title';
import User from './User';
import { useQuery } from '@apollo/client';
import { FETCH_ME } from '../../../graphql/users/queries';
import { userIsCustomer } from '../../createUser/userSelectOptions';
import useExternalScripts from '../../../hooks/useExternalScripts';
import useInlineScripts from '../../../hooks/useInlineScripts';
import { routesWithNewClaimButton } from '../../../pages/routes';
import { useLocation } from 'react-router-dom';

type HeaderProps = {
  title:
    | string
    | { text: { refGT: string; refClient: string }; souffrance: boolean };
};

const Header = ({ title }: HeaderProps) => {
  const [enableClaimButton, setEnableClaimButton] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setEnableClaimButton(routesWithNewClaimButton.includes(location.pathname));
  }, [location]);

  useInlineScripts({script: `
    window.fwSettings={
        widget_id :103000006019
      };
      !function(){
        if("function"!=typeof window.FreshworksWidget){
        var n=function(){n.q.push(arguments)};n.q=[],
        window.FreshworksWidget=n}
      }()
    FreshworksWidget('hide', 'launcher');
   `
  });
  useExternalScripts({
    url: 'https://euc-widget.freshworks.com/widgets/103000006019.js',
    async: true,
    defer: true,
  });
  const { loading, error, data } = useQuery(FETCH_ME, {
    fetchPolicy: 'network-only',
  });

  const openWidget = () => {
    const FreshworksWidget = (window as any).FreshworksWidget;
    FreshworksWidget('open');
  };

  return (
      <header id="header">
        <div className="header-left">
          <Logo />
          <Title title={title} />
        </div>
        {!loading && data && (
            <div className="header-right">
              {data.Me.searchAuthorized && <Searchbar />}
              <div className={enableClaimButton ? 'freshdesk-widgetButton--wrapper' : ''}>
                <User
                  firstName={data.Me.firstName}
                  lastName={data.Me.lastName}
                  id={data.Me.id}
                  isCustomer={userIsCustomer(data?.Me.roles)}
                />
                {enableClaimButton && (
                    <button type="button"
                            className="btn btn-small freshdesk-widgetButton"
                            onClick={openWidget}>Nouvelle réclamation</button>
                )}
              </div>
            </div>
        )}

      </header>
  );
};

export default Header;
