import { useEffect } from 'react';

type externalScriptsParam = {
    url: string,
    async: boolean,
    defer: boolean
};
export default function useExternalScripts ({ url, async= false, defer = false }: externalScriptsParam) {
    useEffect(() => {
        const externalScript = document.createElement('script');

        externalScript.src = url;
        externalScript.async = async;
        externalScript.defer = defer;

        document.body.appendChild(externalScript);

        return () => {
            externalScript.remove();
        };
    }, [url]);
}
