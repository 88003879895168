import React from 'react';
import Logo from '../header/Logo';
import logo from '../../../../theme/img/gt-logo-white.jpg';
import logo2 from '../../../../theme/img/gt-logo.svg';
import { routes } from '../../../pages/routes';
import { Link } from 'react-router-dom';

type FooterProps = {
  extraClass?: string;
};

const Footer = ({ extraClass }: FooterProps) => (
  <footer id="main-footer" className={extraClass}>
    <Link to={routes.search} className="link-to-home">
      <img src={logo} alt="GT SOLUTIONS" className="logo" />
    </Link>
    {/* <div className="footer-item contact">
      <p>Contact 
      05 57 80 83 80</p>
    </div> */}

    <div className="footer-item">
      <Link to={routes.legalsCookie}>
        Politique de gestion des cookies
      </Link>
      <a href="http://www.gt-solutions.fr" className="ext-link" target="_blank">
        www.gt-solutions.fr
      </a>
      <Link to={routes.legalsRGPD}>
        Politique de protection des données personnelles
      </Link>
    </div>

    {/*<div className="footer-item">
       <a type="mail" href="mailto:Assistance@gt-solutions.fr">
        Assistance@gt-solutions.fr
      </a> 
    </div>*/}
  </footer>
);

export default Footer;
