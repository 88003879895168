import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

export const faDashboardIcon: IconDefinition = {
  prefix: 'fas' as IconPrefix,
  iconName: 'customDashboard' as IconName,
  icon: [
    22,
    31,
    [],
    'f0000',
    'M2.88,18.25A2.88,2.88,0,0,1,0,15.37V2.88A2.88,2.88,0,0,1,2.88,0h4.8a2.88,2.88,0,0,1,2.88,2.88V15.37a2.88,2.88,0,0,1-2.88,2.88Z M2.88,31A2.88,2.88,0,0,1,0,28.12V22.36a2.88,2.88,0,0,1,2.88-2.89h4.8a2.88,2.88,0,0,1,2.88,2.89v5.76A2.88,2.88,0,0,1,7.68,31Z M14.32,13.45a2.88,2.88,0,0,1-2.88-2.89V2.88A2.88,2.88,0,0,1,14.32,0h4.8A2.88,2.88,0,0,1,22,2.88v7.68a2.88,2.88,0,0,1-2.88,2.89Z M14.32,31a2.88,2.88,0,0,1-2.88-2.88V17.55a2.88,2.88,0,0,1,2.88-2.88h4.8A2.88,2.88,0,0,1,22,17.55V28.12A2.88,2.88,0,0,1,19.12,31Z'
  ]
};
